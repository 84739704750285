import { gql } from "urql";

export const ListAirports = gql`
  query ListAirportsQuery(
    $limit: Int
    $offset: Int = 0
    $where: airports_bool_exp = {}
  ) {
    airports(
      limit: $limit
      offset: $offset
      order_by: { name: asc }
      where: $where
    ) {
      airport_code
      id
      name
      status
    }
    airports_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const ListAirlines = gql`
  query ListAirlines(
    $limit: Int
    $offset: Int = 0
    $where: airlines_bool_exp = {}
  ) {
    airlines(
      limit: $limit
      offset: $offset
      order_by: { name: asc }
      where: $where
    ) {
      id
      name
      status
      iata_code
    }
    airlines_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const DeleteAirlineByPK = gql`
  mutation DeleteAirlineByPk(
    $id: uuid = ""
    $description: String = ""
    $user_id: uuid = ""
  ) {
    delete_airlines_by_pk(id: $id) {
      id
    }
    insert_activities_one(
      object: {
        description: $description
        type: "inventory deleted"
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;

export const DeleteAirportByPK = gql`
  mutation DeleteAirportByPk(
    $id: uuid = ""
    $description: String = ""
    $user_id: uuid = ""
  ) {
    delete_airports_by_pk(id: $id) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory deleted"
        description: $description
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export const AddAirline = gql`
  mutation AddAirline(
    $object: airlines_insert_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    insert_airlines_one(object: $object) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory added"
        user_id: $user_id
        description: $description
      }
    ) {
      id
    }
  }
`;

export const AddAirport = gql`
  mutation AddAirport(
    $object: airports_insert_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    insert_airports_one(object: $object) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory added"
        description: $description
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export const UpdateAirlines = gql`
  mutation UpdateAirlines(
    $id: uuid = ""
    $_set: airlines_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    update_airlines_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    insert_activities_one(
      object: {
        description: $description
        type: "inventory updated"
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export const UpdateAirport = gql`
  mutation UpdateAirport(
    $id: uuid = ""
    $_set: airports_set_input = {}
    $user_id: uuid = ""
    $description: String = ""
  ) {
    update_airports_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory updated"
        user_id: $user_id
        description: $description
      }
    ) {
      id
    }
  }
`;

export const ListCategories = gql`
  query ListCategories {
    categories(order_by: { created_at: desc }) {
      name
      id
    }
  }
`;

export const GetSchemaByPK = gql`
  query GetSchemaByPK($id: uuid = "") {
    categories_by_pk(id: $id) {
      schema
    }
  }
`;
export const InsertCategory = gql`
  mutation InsertCategory(
    $object: categories_insert_input = {}
    $user_id: uuid = ""
    $description: String = ""
  ) {
    insert_categories_one(object: $object) {
      id
      name
    }
    insert_activities_one(
      object: {
        type: "inventory added"
        user_id: $user_id
        description: $description
      }
    ) {
      id
    }
  }
`;
export const UpdateCategory = gql`
  mutation UpdateCategory(
    $id: uuid = ""
    $_set: categories_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    update_categories_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory updated"
        description: $description
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;

export const DeleteCategoryByPK = gql`
  mutation DeleteCategoryByPK(
    $id: uuid = ""
    $description: String = ""
    $user_id: uuid = ""
  ) {
    delete_categories_by_pk(id: $id) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory deleted"
        description: $description
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export const ListCarRentals = gql`
  query ListCarRentalssQuery(
    $limit: Int
    $offset: Int = 0
    $where: car_rental_bool_exp = {}
  ) {
    car_rental(
      limit: $limit
      offset: $offset
      order_by: { name: asc }
      where: $where
    ) {
      id
      name
      status
    }
    car_rental_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const DeleteCarRentalByPK = gql`
  mutation DeleteCarRentalByPk(
    $id: uuid = ""
    $user_id: uuid = ""
    $description: String = ""
  ) {
    delete_car_rental_by_pk(id: $id) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory deleted"
        user_id: $user_id
        description: $description
      }
    ) {
      id
    }
  }
`;
export const AddCarRental = gql`
  mutation AddCarRental(
    $object: car_rental_insert_input = {}
    $user_id: uuid = ""
    $description: String = ""
  ) {
    insert_car_rental_one(object: $object) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory added"
        user_id: $user_id
        description: $description
      }
    ) {
      id
    }
  }
`;
export const UpdateCarRentals = gql`
  mutation UpdateCarRental(
    $id: uuid = ""
    $_set: car_rental_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    update_car_rental_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory updated"
        description: $description
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export const ListThemeParks = gql`
  query ListThemeParkssQuery(
    $limit: Int
    $offset: Int = 0
    $where: theme_park_bool_exp = {}
  ) {
    theme_park(
      limit: $limit
      offset: $offset
      order_by: { name: asc }
      where: $where
    ) {
      id
      name
      state
    }
    theme_park_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const DeleteThemeParkByPK = gql`
  mutation DeleteThemeParkByPk(
    $id: uuid = ""
    $user_id: uuid = ""
    $description: String = ""
  ) {
    delete_theme_park_by_pk(id: $id) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory deleted"
        user_id: $user_id
        description: $description
      }
    ) {
      id
    }
  }
`;
export const AddThemePark = gql`
  mutation AddThemePark(
    $object: theme_park_insert_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    insert_theme_park_one(object: $object) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory added"
        description: $description
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export const UpdateThemePArks = gql`
  mutation UpdateThemePark(
    $id: uuid = ""
    $_set: theme_park_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    update_theme_park_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    insert_activities_one(
      object: {
        type: "inventory updated"
        description: $description
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;

export const AirlineByPk = gql`
  query AirlineByPk($id: uuid = "") {
    airlines_by_pk(id: $id) {
      iata_code
      name
      status
      id
      created_at
      updated_at
      addresses {
        contact_person
        email
        phone_number
        street
        city
        state
        additional_notes
        url
      }
      addresses_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
export const AirportsByPk = gql`
  query AirportByPk($id: uuid = "") {
    airports_by_pk(id: $id) {
      name
      status
      id
      created_at
      updated_at
      addresses {
        contact_person
        email
        phone_number
        street
        city
        state
        additional_notes
        url
      }
      addresses_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
export const CarRentalByPk = gql`
  query CarRentalByPK($id: uuid = "") {
    car_rental_by_pk(id: $id) {
      name
      status
      id
      created_at
      updated_at
      addresses {
        contact_person
        email
        phone_number
        street
        city
        state
        additional_notes
        url
      }
      addresses_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
export const ThemeParkByPk = gql`
  query ThemeParkByPk($id: uuid = "") {
    theme_park_by_pk(id: $id) {
      name
      id
      created_at
      updated_at
      addresses {
        contact_person
        email
        phone_number
        street
        city
        state
        additional_notes
        url
      }
      addresses_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
