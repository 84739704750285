import { gql } from "urql";
export const ListApplicationsQuery = gql`
  subscription ListApplications(
    $limit: Int
    $offset: Int
    $where: application_bool_exp = {}
  ) {
    application(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { claim_id: desc }
    ) {
      status
      id
      created_at
      application_type
      claim_id
      first_name
      last_name
    }
  }
`;
export const ListApplicationCount = gql`
  subscription ListApplicationsCount($where: application_bool_exp = {}) {
    application_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const ListClosedReportsQuery = gql`
  subscription ListApplication(
    $limit: Int
    $offset: Int
    $where: application_bool_exp = {}
  ) {
    application(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { claim_id: desc }
    ) {
      id
      created_at
      application_type
      claim_id
      date_started
    }
  }
`;

export const ApplicationDetailsQuery = gql`
  query ApplicationDetailsQuery($id: uuid = "") {
    application_by_pk(id: $id) {
      claim_id
      phone_number
      first_name
      last_name
      status
      address_personal
      state
      zip
      country
      city
      address
      id
      created_at
      updated_at
      closest_reason
      closing_month_of_accounting_year
      application_type
      general_information
      email_address
      date_started
      general_questions
      information
      primary_activity
      assigned_to
      active_user
      active_user_updated_at
      is_pass
      marked_urgent_at
      netracer
      netracer_last_switched_at
      review
      google
    }
    config_by_pk(id: "9faf62fe-4f1e-494e-9e27-a3e0e68fed68") {
      static_mail
      price
      move_to_in_process
    }
  }
`;

export const ActivityFeedsQuery = gql`
  query ActivityFeedsQuery($id: uuid!) {
    application_activities(
      where: { application_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      activity_type
      description
      note
      created_at
      user_id
      id
      email_log_id
      email_log {
        body
        subject
        email_template {
          temp_name
        }
      }
      user {
        name
        email
      }
    }
  }
`;

export const AddCommentMutation = gql`
  mutation AddApplicationActivity(
    $object: application_activities_insert_input = {}
  ) {
    insert_application_activities_one(object: $object) {
      id
    }
  }
`;
export const EditCommentMutation = gql`
  mutation EditComment($id: uuid = "", $note: String = "") {
    update_application_activities_by_pk(
      pk_columns: { id: $id }
      _set: { note: $note }
    ) {
      id
    }
  }
`;
export const UpdateApplicationMutation = gql`
  mutation UpdateApplicationMutation(
    $id: uuid = ""
    $_set: application_set_input = {}
  ) {
    update_application_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      assigned_to
    }
  }
`;
export const UpdateReportAndActivityMutation = gql`
  mutation UpdateApplicationMutation(
    $id: uuid = ""
    $_set: application_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
    $activity_type: String = ""
  ) {
    update_application_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      assigned_to
    }
    insert_application_activities_one(
      object: {
        description: $description
        application_id: $id
        user_id: $user_id
        activity_type: $activity_type
      }
    ) {
      id
    }
  }
`;
export const ManuallyMarkPaymentMutation = gql`
  mutation UpdateManualPaymentMutation(
    $report_id: uuid = ""
    $user_id: uuid = ""
    $description: String = ""
    $amount: float8 = ""
  ) {
    insert_payment_logs_one(
      object: {
        manually_marked_by: $user_id
        payment_method: "manual"
        report_id: $report_id
        status: "paid"
        amount: $amount
      }
    ) {
      id
    }
    insert_report_activities_one(
      object: {
        activity_type: "payment_marked"
        description: $description
        report_id: $report_id
        user_id: $user_id
      }
    ) {
      id
    }
    update_reports_by_pk(
      pk_columns: { id: $report_id }
      _set: { status: "open" }
    ) {
      id
    }
  }
`;
export const ChangeStatusMutation = gql`
  mutation ChangeStatusMutation(
    $id: uuid = ""
    $_set: application_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    update_application_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      status
    }
    insert_application_activities_one(
      object: {
        activity_type: "status_change"
        description: $description
        user_id: $user_id
        application_id: $id
      }
    ) {
      id
    }
  }
`;
export const AssignToUser = gql`
  mutation UpdateApplicationMutation(
    $id: uuid = ""
    $_set: application_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
    $activity_type: String = ""
  ) {
    update_application_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      assigned_to
    }
    insert_application_activities_one(
      object: {
        activity_type: $activity_type
        description: $description
        application_id: $id
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export const GetCategoryByPKQuery = gql`
  query CategoryByPKQuery($id: uuid = "") {
    categories_by_pk(id: $id) {
      id
      name
      schema
    }
  }
`;

export const GetPaymentSuccessTemplate = gql`
  query GetPaymentSuccessTemplate {
    email_templates_by_pk(id: "ab8c6faa-e22e-416b-aa29-c8919fb9deda") {
      message
      id
      signature
      subject
      temp_name
    }
  }
`;
export const FetchPaidEmails = gql`
  query FetchPaidEmails {
    email_templates(
      where: { condition: { _eq: "paid" } }
      order_by: { order: asc_nulls_first }
    ) {
      bg_color
      border_color
      id
      temp_name
      label
    }
  }
`;

export const AddStickyMutation = gql`
  mutation AddSticky(
    $added_by: uuid = ""
    $report_id: uuid = ""
    $text: String = ""
    $description: String = ""
  ) {
    insert_sticky_one(
      object: { added_by: $added_by, application_id: $report_id, text: $text }
    ) {
      id
    }
    insert_application_activities_one(
      object: {
        activity_type: "sticky added"
        user_id: $added_by
        note: $text
        description: $description
        application_id: $report_id
      }
    ) {
      id
    }
  }
`;

export const GetLastStickyQuery = gql`
  query GetLastSticky($_eq: uuid = "") {
    sticky(
      limit: 1
      where: { application_id: { _eq: $_eq } }
      order_by: { created_at: desc }
    ) {
      id
      text
      status
    }
  }
`;

export const UpdateStickyMutation = gql`
  mutation UpdateStickyMutation(
    $id: uuid = ""
    $_set: sticky_set_input = {}
    $object: application_activities_insert_input = {}
  ) {
    update_sticky_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    insert_application_activities_one(object: $object) {
      id
    }
  }
`;
