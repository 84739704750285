import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const Home = lazy(() => import("../pages/home"));
const Application = lazy(() => import("../pages/application"));
const NotPaidClosed = lazy(() => import("../pages/closed_not_paid"));
const EmailTemplates = lazy(() => import("../pages/email_templates"));
const AddressBook = lazy(() => import("../pages/address_book"));
const Users = lazy(() => import("../pages/users"));
const Inventories = lazy(() => import("../pages/inventories"));
const Messages = lazy(() => import("../pages/messages"));
const WhiteBoard = lazy(() => import("../pages/white_board"));
const EmailLogs = lazy(() => import("../pages/email_logs"));
const PaymentLogs = lazy(() => import("../pages/payment_logs"));
const ActivityLogs = lazy(() => import("../pages/activity_logs"));
const Profile = lazy(() => import("../pages/profile"));
const OtherActivities = lazy(() => import("../pages/other_activitie_logs"));
const Page404 = lazy(() => import("../pages/404"));
const routes = [
  {
    path: "/", // the url
    component: Home, // view rendered
  },
  {
    path: "/active-applications", // the url
    component: Home, // view rendered
  },
  // {
  //   path: "/closed-reports", // the url
  //   component: ClosedReports, // view rendered
  // },
  // {
  //   path: "/not-found-reports", // the url
  //   component: NotFound, // view rendered
  // },
  // {
  //   path: "/found-reports", // the url
  //   component: Found, // view rendered
  // },
  {
    path: "/not-paid-closed-reports", // the url
    component: NotPaidClosed, // view rendered
  },
  // {
  //   path: "/refunded-reports", // the url
  //   component: Refunded, // view rendered
  // },
  {
    path: "/application/:id", // the url
    component: Application, // view rendered
  },
  {
    path: "/404", // the url
    component: Page404, // view rendered
  },
  { component: EmailTemplates, path: "/email-templates" },
  { component: AddressBook, path: "/address-book" },
  { component: Users, path: "/users" },
  {
    component: Inventories,
    path: "/inventories",
  },
  { component: Messages, path: "/messages" },
  { component: WhiteBoard, path: "/white-board" },
  { component: EmailLogs, path: "/email-logs" },
  { component: PaymentLogs, path: "/payment-logs" },
  { component: ActivityLogs, path: "/activity-logs" },
  { component: OtherActivities, path: "/logs" },
  {
    component: Profile,
    path: "/profile",
  },
];

export default routes;
